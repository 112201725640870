<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 4.5C5.69772 4.5 5.25 4.94772 5.25 5.5V13.5C5.25 14.0523 5.69772 14.5 6.25 14.5C6.25 15.0523 6.69772 15.5 7.25 15.5H15.25C15.8023 15.5 16.25 15.0523 16.25 14.5V6.5C16.25 5.94772 15.8023 5.5 15.25 5.5C15.25 4.94772 14.8023 4.5 14.25 4.5H6.25ZM14 5.5C14.1381 5.5 14.25 5.61193 14.25 5.75V13.25C14.25 13.3881 14.1381 13.5 14 13.5H6.5C6.36193 13.5 6.25 13.3881 6.25 13.25V5.75C6.25 5.61193 6.36193 5.5 6.5 5.5H14Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'ShadowLight'
};
</script>
