<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 5C5.11193 5 5 5.11193 5 5.25V15.75C5 16.1642 4.66421 16.5 4.25 16.5C3.83579 16.5 3.5 16.1642 3.5 15.75V5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H15.75C16.1642 3.5 16.5 3.83579 16.5 4.25C16.5 4.66421 16.1642 5 15.75 5H5.25Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerSquare'
};
</script>
