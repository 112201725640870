<template>
  <svg viewBox="0 0 50 74" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M0 0H50V40.1667C50 41.8235 48.6569 43.1667 47 43.1667H3C1.34315 43.1667 0 41.8235 0 40.1667V0Z" fill="#5E5E5E"/>
<path d="M0 48.2222C0 46.5653 1.34315 45.2222 3 45.2222H47C48.6569 45.2222 50 46.5653 50 48.2222V73.9999H0V48.2222Z" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutOneCol'
};
</script>
