import gpAbTestingTabIcon from './icons/ABTestingTab.vue';
import gpBorderBottomIcon from './icons/BorderBottom.vue';
import gpBorderFullIcon from './icons/BorderFull.vue';
import gpBorderLeftIcon from './icons/BorderLeft.vue';
import gpBorderRightIcon from './icons/BorderRight.vue';
import gpBorderSeparatedIcon from './icons/BorderSeparated.vue';
import gpBorderTopIcon from './icons/BorderTop.vue';
import gpCornerPillIcon from './icons/CornerPill.vue';
import gpCornerRoundedIcon from './icons/CornerRounded.vue';
import gpCornerSingleIcon from './icons/CornerSingle.vue';
import gpCornerSquareIcon from './icons/CornerSquare.vue';
import gpCustomizeIcon from './icons/Customize.vue';
import gpDashIcon from './icons/Dash.vue';
import gpDotsIcon from './icons/Dots.vue';
import gpJustifyContentBottomIcon from './icons/JustifyContentBottom.vue';
import gpJustifyContentCenterIcon from './icons/JustifyContentCenter.vue';
import gpJustifyContentLeftIcon from './icons/JustifyContentLeft.vue';
import gpJustifyContentMiddleIcon from './icons/JustifyContentMiddle.vue';
import gpJustifyContentRightIcon from './icons/JustifyContentRight.vue';
import gpJustifyContentTopIcon from './icons/JustifyContentTop.vue';
import gpLineIcon from './icons/Line.vue';
import gpLinkIcon from './icons/Link.vue';
import gpLoadingIcon from './icons/Loading.vue';
import gpPaddingIcon from './icons/Padding.vue';
import gpPaddingLinkedIcon from './icons/PaddingLinked.vue';
import gpPaddingSeparateIcon from './icons/PaddingSeparate.vue';
import gpPaddingSeparatorIcon from './icons/PaddingSeparator.vue';
import gpShadowHardIcon from './icons/ShadowHard.vue';
import gpShadowLightIcon from './icons/ShadowLight.vue';
import gpShadowMediumIcon from './icons/ShadowMedium.vue';
import gpTextAlignJustifyIcon from './icons/TextAlignJustify.vue';
import gpUnlinkIcon from './icons/Unlink.vue';

export const icons = {
  'gp-ab-testing-tab': gpAbTestingTabIcon,
  'gp-border-bottom': gpBorderBottomIcon,
  'gp-border-full': gpBorderFullIcon,
  'gp-border-left': gpBorderLeftIcon,
  'gp-border-right': gpBorderRightIcon,
  'gp-border-separated': gpBorderSeparatedIcon,
  'gp-border-top': gpBorderTopIcon,
  'gp-corner-pill': gpCornerPillIcon,
  'gp-corner-rounded': gpCornerRoundedIcon,
  'gp-corner-single': gpCornerSingleIcon,
  'gp-corner-square': gpCornerSquareIcon,
  'gp-customize': gpCustomizeIcon,
  'gp-dash': gpDashIcon,
  'gp-dots': gpDotsIcon,
  'gp-justify-content-bottom': gpJustifyContentBottomIcon,
  'gp-justify-content-center': gpJustifyContentCenterIcon,
  'gp-justify-content-left': gpJustifyContentLeftIcon,
  'gp-justify-content-middle': gpJustifyContentMiddleIcon,
  'gp-justify-content-right': gpJustifyContentRightIcon,
  'gp-justify-content-top': gpJustifyContentTopIcon,
  'gp-line': gpLineIcon,
  'gp-link': gpLinkIcon,
  'gp-loading': gpLoadingIcon,
  'gp-padding': gpPaddingIcon,
  'gp-padding-linked': gpPaddingLinkedIcon,
  'gp-padding-separate': gpPaddingSeparateIcon,
  'gp-padding-separator': gpPaddingSeparatorIcon,
  'gp-shadow-hard': gpShadowHardIcon,
  'gp-shadow-light': gpShadowLightIcon,
  'gp-shadow-medium': gpShadowMediumIcon,
  'gp-text-align-justify': gpTextAlignJustifyIcon,
  'gp-unlink': gpUnlinkIcon,
};
