<template>
  <svg viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M0 3C0 1.34315 1.34315 0 3 0H9C10.6569 0 12 1.34315 12 3V9C12 10.6569 10.6569 12 9 12H3C1.34315 12 0 10.6569 0 9V3Z" fill="#5E5E5E"/>
<path d="M0 17C0 15.3431 1.34315 14 3 14H9C10.6569 14 12 15.3431 12 17V23C12 24.6569 10.6569 26 9 26H3C1.34315 26 0 24.6569 0 23V17Z" fill="#5E5E5E"/>
<path d="M0 31C0 29.3431 1.34315 28 3 28H9C10.6569 28 12 29.3431 12 31V37C12 38.6569 10.6569 40 9 40H3C1.34315 40 0 38.6569 0 37V31Z" fill="#5E5E5E"/>
<path d="M14 3C14 1.34315 15.3431 0 17 0H51C52.6569 0 54 1.34315 54 3V41C54 42.6569 52.6569 44 51 44H17C15.3431 44 14 42.6569 14 41V3Z" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutLeft'
};
</script>
