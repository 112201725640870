<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 3.5C5.44772 3.5 5 3.94772 5 4.5V5H4.5C3.94772 5 3.5 5.44772 3.5 6V14C3.5 14.5523 3.94772 15 4.5 15H5V15.5C5 16.0523 5.44772 16.5 6 16.5H14C14.5523 16.5 15 16.0523 15 15.5V15H15.5C16.0523 15 16.5 14.5523 16.5 14V6C16.5 5.44772 16.0523 5 15.5 5H15V4.5C15 3.94772 14.5523 3.5 14 3.5H6ZM15 5V15H5V5H15Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BorderFull'
};
</script>
