<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path d="M11.75 5C12.1642 5 12.5 4.66421 12.5 4.25C12.5 3.83579 12.1642 3.5 11.75 3.5H10.25C9.83579 3.5 9.5 3.83579 9.5 4.25C9.5 4.66421 9.83579 5 10.25 5L11.75 5Z" fill="currentColor"/>
<path d="M15.75 6.5C15.3358 6.5 15 6.16421 15 5.75V5.25C15 5.11193 14.8881 5 14.75 5L14.25 5C13.8358 5 13.5 4.66421 13.5 4.25C13.5 3.83579 13.8358 3.5 14.25 3.5H14.75C15.7165 3.5 16.5 4.2835 16.5 5.25V5.75C16.5 6.16421 16.1642 6.5 15.75 6.5Z" fill="currentColor"/>
<path d="M15.75 7.5C16.1642 7.5 16.5 7.83579 16.5 8.25V9.75C16.5 10.1642 16.1642 10.5 15.75 10.5C15.3358 10.5 15 10.1642 15 9.75V8.25C15 7.83579 15.3358 7.5 15.75 7.5Z" fill="currentColor"/>
<path d="M6.25 12C5.83579 12 5.5 11.6642 5.5 11.25L5.5 6.06066L4.53033 7.03033C4.23744 7.32322 3.76256 7.32322 3.46967 7.03033C3.17678 6.73744 3.17678 6.26256 3.46967 5.96967L5.71967 3.71967C6.01256 3.42678 6.48744 3.42678 6.78033 3.71967L9.03033 5.96967C9.32322 6.26256 9.32322 6.73744 9.03033 7.03033C8.73744 7.32322 8.26256 7.32322 7.96967 7.03033L7 6.06066L7 11.25C7 11.6642 6.66421 12 6.25 12Z" fill="currentColor"/>
<path d="M8.75 13C8.33579 13 8 13.3358 8 13.75C8 14.1642 8.33579 14.5 8.75 14.5H13.9393L12.9697 15.4697C12.6768 15.7626 12.6768 16.2374 12.9697 16.5303C13.2626 16.8232 13.7374 16.8232 14.0303 16.5303L16.2803 14.2803C16.5732 13.9874 16.5732 13.5126 16.2803 13.2197L14.0303 10.9697C13.7374 10.6768 13.2626 10.6768 12.9697 10.9697C12.6768 11.2626 12.6768 11.7374 12.9697 12.0303L13.9393 13H8.75Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Padding'
};
</script>
