<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.49505 3.74676L17.495 3.74512C17.8402 3.74508 18.1201 4.02487 18.1201 4.37005C18.1202 4.71523 17.8404 4.99508 17.4952 4.99512L2.49519 4.99676C2.15001 4.9968 1.87016 4.71701 1.87012 4.37183C1.87008 4.02665 2.14987 3.7468 2.49505 3.74676Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.49505 7.4992L17.495 7.49756C17.8402 7.49752 18.1201 7.77731 18.1201 8.12249C18.1202 8.46767 17.8404 8.74752 17.4952 8.74756L2.49519 8.7492C2.15001 8.74924 1.87016 8.46945 1.87012 8.12427C1.87008 7.77909 2.14987 7.49924 2.49505 7.4992Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.49505 15.0037L13.7507 15.0024C14.0959 15.0024 14.3757 15.2822 14.3758 15.6274C14.3758 15.9725 14.096 16.2524 13.7508 16.2524L2.49519 16.2537C2.15001 16.2537 1.87016 15.9739 1.87012 15.6287C1.87008 15.2836 2.14987 15.0037 2.49505 15.0037Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.49505 11.2516L17.495 11.25C17.8402 11.25 18.1201 11.5298 18.1201 11.8749C18.1202 12.2201 17.8404 12.5 17.4952 12.5L2.49519 12.5016C2.15001 12.5017 1.87016 12.2219 1.87012 11.8767C1.87008 11.5315 2.14987 11.2517 2.49505 11.2516Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'TextAlignJustify'
};
</script>
