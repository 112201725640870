<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.25 5C8.24594 5 5 8.24594 5 12.25V15.75C5 16.1642 4.66421 16.5 4.25 16.5C3.83579 16.5 3.5 16.1642 3.5 15.75V12.25C3.5 7.41751 7.41751 3.5 12.25 3.5H15.75C16.1642 3.5 16.5 3.83579 16.5 4.25C16.5 4.66421 16.1642 5 15.75 5H12.25Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerPill'
};
</script>
