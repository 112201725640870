<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 4C4.69772 4 4.25 4.44772 4.25 5V13C4.25 13.5523 4.69772 14 5.25 14H6.25V15C6.25 15.5523 6.69772 16 7.25 16H15.25C15.8023 16 16.25 15.5523 16.25 15V7C16.25 6.44772 15.8023 6 15.25 6H14.25V5C14.25 4.44772 13.8023 4 13.25 4H5.25ZM13 5C13.1381 5 13.25 5.11193 13.25 5.25V12.75C13.25 12.8881 13.1381 13 13 13H5.5C5.36193 13 5.25 12.8881 5.25 12.75V5.25C5.25 5.11193 5.36193 5 5.5 5H13Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'ShadowMedium'
};
</script>
