<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path class="text-dark-50" fill-rule="evenodd" clip-rule="evenodd" d="M3.5 2C2.67157 2 2 2.67157 2 3.5L2 16.5C2 17.3284 2.67157 18 3.5 18H16.5C17.3284 18 18 17.3284 18 16.5L3.5 16.5L3.5 3.5H18C18 2.67157 17.3284 2 16.5 2H3.5Z" fill="currentColor"/>
<path d="M16 2C17.1046 2 18 2.89543 18 4L18 16C18 17.1046 17.1046 18 16 18L16 2Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BorderRight'
};
</script>
