import type { Entries, ObjectDevices, ScreenType } from '../types/custom';
import { isObject } from './component';

export const removeNullUndefined = <T extends Record<string, any>>(obj: T) =>
  (Object.entries(obj) as Entries<T>).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {} as T);

export const getResponsiveValueByScreen = <T>(
  value?: ObjectDevices<T>,
  breakpoint?: keyof ObjectDevices<T>,
  defaultValue?: T,
) => {
  const desktop = checkEmptyValue(value?.desktop) ? undefined : value?.desktop;
  const tablet = checkEmptyValue(value?.tablet) ? undefined : value?.tablet;
  const mobile = checkEmptyValue(value?.mobile) ? undefined : value?.mobile;
  switch (breakpoint) {
    case 'tablet':
      return tablet ?? desktop ?? defaultValue;
    case 'mobile':
      return mobile ?? tablet ?? desktop ?? defaultValue;
    default:
      return desktop ?? defaultValue;
  }
};

export const checkEmptyValue = (value: any) => {
  if (isObject(value)) return isEmptyObject(value);
  if (typeof value === 'string' && value.trim() !== '') return false;
  return value == undefined;
};

const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const fontWeightNameMapping: Record<string, string> = {
  '100': 'Thin',
  '200': 'Extra Light',
  '300': 'Light',
  '400': 'Normal',
  '500': 'Medium',
  '600': 'Semi Bold',
  '700': 'Bold',
  '800': 'Extra Bold',
  '900': 'Black',
  '950': 'Extra Black',
};

export function isObjectEqual(obj1: any, obj2: any): boolean {
  // check if the objects are the same object
  if (obj1 === obj2) return true;

  // check if the objects are null or not objects
  if (obj1 == null || typeof obj1 != 'object' || obj2 == null || typeof obj2 != 'object') return false;

  // compare the number of properties in the objects
  const keysA = Object.keys(obj1),
    keysB = Object.keys(obj2);
  if (keysA.length != keysB.length) return false;

  // compare the properties and values of the objects
  for (const key of keysA) {
    if (!keysB.includes(key) || !isObjectEqual(obj1[key], obj2[key])) return false;
  }

  // if all of the properties and values are the same, the objects are equal
  return true;
}
export const checkSortedArray = (arr: any[]) => {
  return arr.every((value, index, array) => index === 0 || value >= array[index - 1]);
};

export const changePointerEvent = (disable = true) => {
  const settingPanel = document.getElementById('setting-panel');
  const sidebar = document.getElementById('sidebar');
  const header = document.getElementById('header');
  if (sidebar && header && settingPanel) {
    settingPanel.style.pointerEvents = disable ? 'none' : 'auto';
    sidebar.style.pointerEvents = disable ? 'none' : 'auto';
    header.style.pointerEvents = disable ? 'none' : 'auto';
  }
  if (!disable) {
    document.getElementById('force-cursor-style')?.remove();
    return;
  }
  const forceCursorStyle = document.createElement('style');
  forceCursorStyle.innerHTML = '*{cursor: row-resize !important;}';
  forceCursorStyle.id = 'force-cursor-style';
  document.head.appendChild(forceCursorStyle);
};

export const stringInsertToIndex = (currentText: string, addText: string, index: number) => {
  const beforeIndex = currentText.slice(0, index);
  const after = currentText.slice(index, currentText.length);
  return beforeIndex + addText + after;
};

export function extractNestedFieldHasDevice(obj: Partial<Record<ScreenType, any>>, field: string) {
  let result = {};
  const devices = Object.keys(obj) as ScreenType[];
  devices.forEach((device) => {
    if (obj[device]) {
      let currentLevel = obj[device];
      currentLevel = extractNestedField(obj, field);

      result = {
        ...result,
        [device]: currentLevel,
      };
    }
  });

  return result;
}

export function extractNestedField(obj: Partial<Record<string, any>>, field: string) {
  const fieldParts = field.split('.');
  let currentLevel: any = obj;
  for (const part of fieldParts) {
    if (currentLevel?.[part]) {
      currentLevel = currentLevel[part];
    } else {
      currentLevel = undefined;
      break;
    }
  }
  return currentLevel;
}

export function checkExistNestedField(obj: Partial<Record<string, any>>, field: string) {
  const keys = field.split('.');

  return (
    keys.reduce((acc, key) => {
      if (acc && typeof acc === 'object' && key in acc) {
        return acc[key];
      }
      return undefined;
    }, obj) !== undefined
  );
}

export function getValueNestedField(obj: Partial<Record<string, any>>, field: string) {
  const keys = field.split('.');
  return keys.reduce((acc, key) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return acc[key];
    }
    return undefined;
  }, obj);
}

export function updateValueNestedField(obj: Record<string, any>, path: string, value: any): void {
  const keys = path.split('.');
  let current: Record<string, any> = obj;

  keys.forEach((key, index) => {
    if (isObject(current)) {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      }
    }
  });
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
