<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 2.5C6.33579 2.5 6 2.83579 6 3.25C6 3.66421 6.33579 4 6.75 4H13.25C13.6642 4 14 3.66421 14 3.25C14 2.83579 13.6642 2.5 13.25 2.5H6.75ZM6.75 16C6.33579 16 6 16.3358 6 16.75C6 17.1642 6.33579 17.5 6.75 17.5H13.25C13.6642 17.5 14 17.1642 14 16.75C14 16.3358 13.6642 16 13.25 16H6.75ZM16.75 14C16.3358 14 16 13.7172 16 13.3684V6.63158C16 6.28277 16.3358 6 16.75 6C17.1642 6 17.5 6.28277 17.5 6.63158V13.3684C17.5 13.7172 17.1642 14 16.75 14ZM2.5 13.3684C2.5 13.7172 2.83579 14 3.25 14C3.66421 14 4 13.7172 4 13.3684V6.63158C4 6.28277 3.66421 6 3.25 6C2.83579 6 2.5 6.28277 2.5 6.63158V13.3684Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'PaddingSeparator'
};
</script>
