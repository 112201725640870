<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<g clip-path="url(#clip0_280_39360)">
  <rect width="32" height="32" fill="#EEEEEE"/>
  <path d="M18.8125 14.0312C18.8125 14.255 18.7236 14.4696 18.5654 14.6279C18.4071 14.7861 18.1925 14.875 17.9688 14.875C17.7452 14.8733 17.531 14.7851 17.3711 14.6289C17.2925 14.5506 17.2303 14.4574 17.188 14.3548C17.1458 14.2522 17.1243 14.1422 17.125 14.0312C17.125 13.8075 17.2139 13.5929 17.3721 13.4346C17.5304 13.2764 17.745 13.1875 17.9688 13.1875C18.1925 13.1875 18.4071 13.2764 18.5654 13.4346C18.7236 13.5929 18.8125 13.8075 18.8125 14.0312ZM23.3125 10.9375V21.0625C23.3125 21.3609 23.194 21.647 22.983 21.858C22.772 22.069 22.4859 22.1875 22.1875 22.1875H9.8125C9.51413 22.1875 9.22798 22.069 9.017 21.858C8.80603 21.647 8.6875 21.3609 8.6875 21.0625V10.9375C8.6875 10.6391 8.80603 10.353 9.017 10.142C9.22798 9.93103 9.51413 9.8125 9.8125 9.8125H22.1875C22.4859 9.8125 22.772 9.93103 22.983 10.142C23.194 10.353 23.3125 10.6391 23.3125 10.9375ZM22.1875 18.5805V10.9375H9.8125V17.4555L12.393 14.875C12.6048 14.6663 12.8902 14.5493 13.1875 14.5493C13.4848 14.5493 13.7702 14.6663 13.982 14.875L17.125 18.018L18.5805 16.5625C18.7923 16.3538 19.0777 16.2368 19.375 16.2368C19.6723 16.2368 19.9577 16.3538 20.1695 16.5625L22.1875 18.5805Z" fill="#494949"/>
</g>
<defs>
  <clipPath id="clip0_280_39360">
    <rect width="32" height="32" fill="white"/>
  </clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'ProductImagePlaceholder'
};
</script>
