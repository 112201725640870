import type {
  ComputePositionConfig,
  ComputePositionReturn,
  Middleware,
  MiddlewareData,
  Placement,
  SideObject,
} from '@floating-ui/core';
import { arrow as arrowCore, autoUpdate, computePosition } from '@floating-ui/dom';
import type { Ref, ToRefs } from 'vue';
import { isRef, onUnmounted, ref, watch } from 'vue';

type Data = Omit<ComputePositionReturn, 'x' | 'y'> & {
  x: number | null;
  y: number | null;
};

type UseFloatingReturn = ToRefs<Data> & {
  update: () => void;
  updateAuto: () => void;
  setParentRef: (ref?: any) => void;
  reference: Ref<Element | undefined>;
  floating: Ref<HTMLElement | undefined>;
};

export function useFloating({
  middleware,
  placement,
  strategy,
}: Omit<Partial<ComputePositionConfig>, 'platform'> = {}): UseFloatingReturn {
  const reference = ref<Element>();
  const parentRef = ref<Element>();
  const floating = ref<HTMLElement>();
  // Setting these to `null` will allow the consumer to determine if
  // `computePosition()` has run yet
  const _cleanup = ref<ReturnType<typeof autoUpdate>>();
  const x = ref<number | null>(null);
  const y = ref<number | null>(null);
  const _strategy = ref(strategy ?? 'fixed');
  const _placement = ref<Placement>('bottom');
  const middlewareData = ref<MiddlewareData>({});

  const setParentRef = (ref?: any) => {
    parentRef.value = ref;
  };

  const update = () => {
    if (!reference.value || !floating.value) {
      return;
    }
    const triggerRef = parentRef.value ? parentRef.value : reference.value;
    computePosition(triggerRef, floating.value, {
      middleware,
      placement,
      strategy,
    }).then((data) => {
      x.value = data.x;
      y.value = data.y < 68 ? 68 : data.y;
      _placement.value = data.placement;
      _strategy.value = data.strategy;
      middlewareData.value = data.middlewareData;
    });
  };

  const updateAuto = () => {
    if (!reference.value || !floating.value) {
      return;
    }
    _cleanup.value = autoUpdate(reference.value, floating.value, update);
  };

  watch(reference, () => updateAuto());
  watch(floating, () => updateAuto());
  watch(parentRef, () => updateAuto());

  onUnmounted(() => {
    if (_cleanup.value) {
      _cleanup.value();
    }
  });

  return {
    x,
    y,
    strategy: _strategy,
    placement: _placement,
    middlewareData,
    update,
    updateAuto,
    setParentRef,
    reference,
    floating,
  };
}

export const arrow = (options: {
  element: Ref<HTMLElement | undefined> | HTMLElement;
  padding?: number | SideObject;
}): Middleware => {
  const { element, padding } = options;

  return {
    name: 'arrow',
    options,
    fn(args: any): any {
      if (isRef(element)) {
        if (element.value != null) {
          return arrowCore({ element: element.value, padding }).fn(args);
        }

        return {};
      } else if (element) {
        return arrowCore({ element, padding }).fn(args);
      }

      return {};
    },
  };
};

export const arrowPlacement = (): Middleware => {
  return {
    name: 'arrowPlacement',
    fn: ({ placement }) => {
      return {
        data: {
          placement,
        },
      };
    },
  };
};
