<template>
  <svg viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M2 14C2 10.6863 4.68629 8 8 8H28C31.3137 8 34 10.6863 34 14V34C34 37.3137 31.3137 40 28 40H8C4.68629 40 2 37.3137 2 34V14Z" fill="#5E5E5E"/>
<path d="M10.5 18.766L15.495 17.0288C15.495 17.0288 16.1024 18.4454 18.005 18.4454C19.9076 18.4454 20.5083 17 20.5083 17L25.5 18.766L24.6692 23.1222H22.1642V31H13.825V23.1196H11.3325L10.5 18.766Z" fill="#D6D6D6"/>
<rect x="38" y="11" width="40" height="6" rx="3" fill="#5E5E5E"/>
<rect x="38" y="21" width="36" height="6" rx="3" fill="#5E5E5E"/>
<rect x="38" y="31" width="24" height="6" rx="3" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductLayoutHorizontal'
};
</script>
