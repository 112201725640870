<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7471 5.30122C16.9912 5.5453 16.9912 5.94103 16.7471 6.18511L7.63139 15.3008C7.51418 15.418 7.35521 15.4839 7.18945 15.4839C7.02368 15.4839 6.86471 15.418 6.7475 15.3008L3.25141 11.8046C3.00734 11.5605 3.00734 11.1648 3.25143 10.9207C3.49551 10.6766 3.89124 10.6766 4.13531 10.9207L7.18946 13.975L15.8632 5.30122C16.1073 5.05714 16.503 5.05714 16.7471 5.30122Z"
      fill="#00C853" />
  </svg>
</template>

<script>
export default {
  name: 'Checkmark',
};
</script>
