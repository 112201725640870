<script lang="ts" setup>
import { GIcon } from '@gem/icons';
import GButton from './GButton.vue';
import type { BannerProps } from '../types/banner';
import { computed, ref } from 'vue';

const props = withDefaults(defineProps<BannerProps>(), {
  type: 'info',
});
const isLightMode = computed(() => !!props.lightMode);

const isShow = ref(true);

const mapTypeToBackground = {
  info: isLightMode.value ? 'underlay-light-blue' : 'underlay-blue-150',
  success: isLightMode.value ? 'functional-green-100' : 'underlay-green-100',
  warning: isLightMode.value ? 'functional-yellow-100' : 'underlay-yellow-150',
  error: isLightMode.value ? 'functional-red-100' : 'underlay-red-150',
};

const backgroundColor = computed(() => mapTypeToBackground[props.type]);

const toggleShow = () => {
  isShow.value = !isShow.value;
};
</script>
<template>
  <div
    v-if="isShow"
    :class="`bg-${backgroundColor}`"
    class="text-12 text-text-dark-500 mb-16 flex gap-8 rounded-xl p-8">
    <div class="text-primary-200 min-w-[20px]"><GIcon :name="`polaris-${type}`" size="20" /></div>
    <div class="flex flex-col">
      <p v-if="title" class="text-14 font-semibold leading-6">{{ title }}</p>
      <p v-if="message" class="text-12 font-regular leading-5">
        {{ message }}
        <template v-if="link"
          ><a :href="link.url" target="_blank" class="text-text-dark-500 underline">{{ link.label }}</a></template
        >
      </p>
      <GButton v-if="primaryButton" type="tertiary" size="normal" @click="() => primaryButton.action()">{{
        primaryButton.label
      }}</GButton>
    </div>
    <div class="min-w-[28px]">
      <GButton type="ghost" size="normal" only-icon="polaris-x" @click="toggleShow"></GButton>
    </div>
  </div>
</template>
