<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 3.5C4.69772 3.5 4.25 3.94772 4.25 4.5V12.5C4.25 13.0523 4.69772 13.5 5.25 13.5H7.25V15.5C7.25 16.0523 7.69772 16.5 8.25 16.5H16.25C16.8023 16.5 17.25 16.0523 17.25 15.5V7.5C17.25 6.94772 16.8023 6.5 16.25 6.5H14.25V4.5C14.25 3.94772 13.8023 3.5 13.25 3.5H5.25ZM13 4.5C13.1381 4.5 13.25 4.61193 13.25 4.75V12.25C13.25 12.3881 13.1381 12.5 13 12.5H5.5C5.36193 12.5 5.25 12.3881 5.25 12.25V4.75C5.25 4.61193 5.36193 4.5 5.5 4.5H13Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'ShadowHard'
};
</script>
