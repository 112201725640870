import HeroBannerCenter from './svgs/HeroBannerCenter.vue';
import HeroBannerCenterActive from './svgs/HeroBannerCenterActive.vue';
import HeroBannerLeft from './svgs/HeroBannerLeft.vue';
import HeroBannerLeftActive from './svgs/HeroBannerLeftActive.vue';
import HeroBannerRight from './svgs/HeroBannerRight.vue';
import HeroBannerRightActive from './svgs/HeroBannerRightActive.vue';
import gpProductImageLayoutBottomIcon from './svgs/ProductImageLayoutBottom.vue';
import gpProductImageLayoutBottomInsideIcon from './svgs/ProductImageLayoutBottomInside.vue';
import gpProductImageLayoutFeaturedOnlyIcon from './svgs/ProductImageLayoutFeaturedOnly.vue';
import gpProductImageLayoutLeftIcon from './svgs/ProductImageLayoutLeft.vue';
import gpProductImageLayoutLeftInsideIcon from './svgs/ProductImageLayoutLeftInside.vue';
import gpProductImageLayoutOneColIcon from './svgs/ProductImageLayoutOneCol.vue';
import gpProductImageLayoutRightIcon from './svgs/ProductImageLayoutRight.vue';
import gpProductImageLayoutRightInsideIcon from './svgs/ProductImageLayoutRightInside.vue';
import gpProductImageLayoutTwoColIcon from './svgs/ProductImageLayoutTwoCol.vue';
import gpProductImagePlaceholderIcon from './svgs/ProductImagePlaceholder.vue';
import gpProductLayoutHorizontalIcon from './svgs/ProductLayoutHorizontal.vue';
import gpProductLayoutVerticalIcon from './svgs/ProductLayoutVertical.vue';
import gpCheckmark from './svgs/Checkmark.vue';
import gpNoResult from './svgs/NoResult.vue';
import gpStatusErrorFilled from './svgs/StatusErrorFilled.vue';

export const icons = {
  'gp-product-image-layout-bottom': gpProductImageLayoutBottomIcon,
  'gp-product-image-layout-bottom-inside': gpProductImageLayoutBottomInsideIcon,
  'gp-product-image-layout-featured-only': gpProductImageLayoutFeaturedOnlyIcon,
  'gp-product-image-layout-left': gpProductImageLayoutLeftIcon,
  'gp-product-image-layout-left-inside': gpProductImageLayoutLeftInsideIcon,
  'gp-product-image-layout-one-col': gpProductImageLayoutOneColIcon,
  'gp-product-image-layout-right': gpProductImageLayoutRightIcon,
  'gp-product-image-layout-right-inside': gpProductImageLayoutRightInsideIcon,
  'gp-product-image-layout-two-col': gpProductImageLayoutTwoColIcon,
  'gp-product-image-placeholder': gpProductImagePlaceholderIcon,
  'gp-product-layout-horizontal': gpProductLayoutHorizontalIcon,
  'gp-product-layout-vertical': gpProductLayoutVerticalIcon,
  'gp-hero-banner-layout-center': HeroBannerCenter,
  'gp-hero-banner-layout-center-active': HeroBannerCenterActive,
  'gp-hero-banner-layout-left': HeroBannerLeft,
  'gp-hero-banner-layout-left-active': HeroBannerLeftActive,
  'gp-hero-banner-layout-right': HeroBannerRight,
  'gp-hero-banner-layout-right-active': HeroBannerRightActive,
  'gp-checkmark': gpCheckmark,
  'gp-no-result': gpNoResult,
  'gp-status-error-filled': gpStatusErrorFilled,
};
