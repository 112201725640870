<script lang="ts" setup>
import { computed } from 'vue';
import { icons } from './components/svgs';

const props = defineProps<{
  name: keyof typeof icons;
  width?: number;
  height?: number;
  spinInfinity?: boolean;
}>();

const icon = computed(() => {
  return icons[props.name as keyof typeof icons];
});
</script>
<template>
  <span
    class="inline-block"
    :class="{
      'animate-spin ease-in-out': spinInfinity,
    }"
    :style="{
      width: width ? `${width}px` : '100%',
      height: height ? `${height}px` : '100%',
    }">
    <component :is="icon" />
  </span>
</template>
