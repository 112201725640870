<template>
  <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M0 3C0 1.34315 1.34315 0 3 0H47C48.6569 0 50 1.34315 50 3V47C50 48.6569 48.6569 50 47 50H3C1.34315 50 0 48.6569 0 47V3Z" fill="#5E5E5E"/>
<rect x="16" y="5" width="12" height="12" rx="2.6" transform="rotate(90 16 5)" fill="#AAAAAA"/>
<rect x="16" y="19" width="12" height="12" rx="2.6" transform="rotate(90 16 19)" fill="#AAAAAA"/>
<rect x="16" y="33" width="12" height="12" rx="2.6" transform="rotate(90 16 33)" fill="#AAAAAA"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutLeftInside'
};
</script>
