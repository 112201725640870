<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path d="M10.75 2.5L9.25001 2.5C7.93826 2.5 6.875 3.56316 6.875 4.87477L6.875 8.12501C6.875 8.47018 6.59517 8.75 6.25 8.75C5.90482 8.75 5.625 8.47017 5.625 8.125L5.625 4.87477C5.625 2.87267 7.24803 1.25 9.25001 1.25H10.75C12.752 1.25 14.375 2.87266 14.375 4.87476L14.375 8.125C14.375 8.47018 14.0952 8.75 13.75 8.75C13.4048 8.75 13.125 8.47017 13.125 8.125L13.125 4.87476C13.125 3.56315 12.0617 2.5 10.75 2.5Z" fill="currentColor"/>
<path d="M6.25001 11.25C6.59519 11.25 6.87501 11.5298 6.875 11.875L6.875 15.1252C6.875 16.4369 7.93826 17.5 9.25 17.5H10.75C12.0617 17.5 13.125 16.4368 13.125 15.1252L13.125 11.875C13.125 11.5298 13.4048 11.25 13.75 11.25C14.0952 11.25 14.375 11.5298 14.375 11.875L14.375 15.1252C14.375 17.1273 12.752 18.75 10.75 18.75H9.25C7.24803 18.75 5.625 17.1273 5.625 15.1252L5.625 11.875C5.62501 11.5298 5.90483 11.25 6.25001 11.25Z" fill="currentColor"/>
<path d="M9.375 13.75C9.375 14.0952 9.65482 14.375 10 14.375C10.3452 14.375 10.625 14.0952 10.625 13.75L10.625 6.25C10.625 5.90482 10.3452 5.625 10 5.625C9.65482 5.625 9.375 5.90482 9.375 6.25L9.375 13.75Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Link'
};
</script>
