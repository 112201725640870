<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
  <path d="M9.25001 2.5L10.75 2.5C12.0617 2.5 13.125 3.56315 13.125 4.87476L13.125 8.125C13.125 8.47017 13.4048 8.75 13.75 8.75C14.0952 8.75 14.375 8.47018 14.375 8.125L14.375 4.87476C14.375 2.87266 12.752 1.25 10.75 1.25H9.25001C7.24803 1.25 5.625 2.87267 5.625 4.87477L5.625 8.125C5.625 8.47017 5.90482 8.75 6.25 8.75C6.59517 8.75 6.875 8.47018 6.875 8.12501L6.875 4.87477C6.875 3.56316 7.93826 2.5 9.25001 2.5Z" fill="currentColor"/>
  <path d="M6.875 11.875C6.87501 11.5298 6.59519 11.25 6.25001 11.25C5.90483 11.25 5.62501 11.5298 5.625 11.875L5.625 15.1252C5.625 17.1273 7.24803 18.75 9.25 18.75H10.75C12.752 18.75 14.375 17.1273 14.375 15.1252L14.375 11.875C14.375 11.5298 14.0952 11.25 13.75 11.25C13.4048 11.25 13.125 11.5298 13.125 11.875L13.125 15.1252C13.125 16.4368 12.0617 17.5 10.75 17.5H9.25C7.93826 17.5 6.875 16.4369 6.875 15.1252L6.875 11.875Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Unlink'
};
</script>
