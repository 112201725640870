<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.39294 4.375C6.35741 4.375 5.51794 5.21447 5.51794 6.25V9.375H1.93433C1.58915 9.375 1.30933 9.65482 1.30933 10C1.30933 10.3452 1.58915 10.625 1.93433 10.625H5.51794V13.75C5.51794 14.7855 6.35741 15.625 7.39294 15.625H12.8929C13.9285 15.625 14.7679 14.7855 14.7679 13.75V10.625H18.3033C18.6485 10.625 18.9283 10.3452 18.9283 10C18.9283 9.65482 18.6485 9.375 18.3033 9.375H14.7679V6.25C14.7679 5.21447 13.9285 4.375 12.8929 4.375H7.39294ZM6.76794 6.25C6.76794 5.90482 7.04777 5.625 7.39294 5.625H12.8929C13.2381 5.625 13.5179 5.90482 13.5179 6.25V13.75C13.5179 14.0952 13.2381 14.375 12.8929 14.375H7.39294C7.04777 14.375 6.76794 14.0952 6.76794 13.75V6.25Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'JustifyContentCenter',
};
</script>
