<template>
  <svg viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M54 3C54 1.34315 52.6569 0 51 0H45C43.3431 0 42 1.34315 42 3V9C42 10.6569 43.3431 12 45 12H51C52.6569 12 54 10.6569 54 9V3Z" fill="#5E5E5E"/>
<path d="M54 17C54 15.3431 52.6569 14 51 14H45C43.3431 14 42 15.3431 42 17V23C42 24.6569 43.3431 26 45 26H51C52.6569 26 54 24.6569 54 23V17Z" fill="#5E5E5E"/>
<path d="M54 31C54 29.3431 52.6569 28 51 28H45C43.3431 28 42 29.3431 42 31V37C42 38.6569 43.3431 40 45 40H51C52.6569 40 54 38.6569 54 37V31Z" fill="#5E5E5E"/>
<path d="M0 3C0 1.34315 1.34315 0 3 0H37C38.6569 0 40 1.34315 40 3V41C40 42.6569 38.6569 44 37 44H3C1.34315 44 0 42.6569 0 41V3Z" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutRight'
};
</script>
