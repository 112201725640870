<script lang="ts" setup>
import { computed } from 'vue';
import { icons } from './iconsLibrary';

const props = withDefaults(
  defineProps<{
    name: keyof typeof icons;
    size?: number;
    spinInfinity?: boolean;
  }>(),
  { size: 20 },
);

const icon = computed(() => {
  return icons[props.name as keyof typeof icons];
});
</script>
<template>
  <span
    class="inline-block"
    :class="{
      'animate-spin ease-in-out': spinInfinity,
    }"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }">
    <component :is="icon" />
  </span>
</template>
