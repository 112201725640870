<script lang="ts" setup>
import { GIcon } from '@gem/icons';
import type { GDropdownMenuItemProps } from '../types/dropdownMenu';
import { computed } from 'vue';
import { DROPDOWN_MENU_ITEM_STYLE_BY_SIZE, DROPDOWN_MENU_ITEM_STYLE_BY_TYPE } from '../const/dropdownMenu';
import { cn } from '@gem/common';

const emit = defineEmits<(e: 'click', event: Event, value: GDropdownMenuItemProps['value']) => void>();

const props = withDefaults(defineProps<GDropdownMenuItemProps>(), {
  lightMode: false,
  size: 'medium',
  icon: undefined,
  title: 'item',
  value: 'item',
  active: false,
  disable: false,
  error: false,
  showCheckedIcon: false,
});

const stylesByTypeAndState = computed((): string => {
  const styles = DROPDOWN_MENU_ITEM_STYLE_BY_TYPE;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.active)
    return props.showCheckedIcon ? cn(styles.activeWithCheckedIcon[mode], styles.hover[mode]) : styles.active[mode];
  if (props.disable) return styles.disabled[mode];
  if (props.error) return styles.error[mode];
  return cn(styles.default[mode], styles.hover[mode]);
});

const stylesBySize = computed((): string =>
  props.size ? DROPDOWN_MENU_ITEM_STYLE_BY_SIZE[props.size] : DROPDOWN_MENU_ITEM_STYLE_BY_SIZE.medium,
);

const iconSizeBySize = computed(() => {
  if (props.size == 'large') return 20;
  if (props.size == 'medium') return 20;
  if (props.size == 'semi-medium') return 16;
  return 16;
});

const handleClick = (e: Event) => {
  !props.disable && emit('click', e, props.value);
};
</script>

<template>
  <div
    class="flex cursor-pointer items-center gap-12 rounded-xl"
    :data-active="active"
    :class="cn([stylesByTypeAndState, stylesBySize])"
    @click="handleClick">
    <template v-if="showCheckedIcon">
      <div :class="active ? 'visible' : 'invisible'" class="text-primary-200 flex items-center">
        <GIcon name="polaris-check" :size="iconSizeBySize" />
      </div>
    </template>
    <template v-if="icon">
      <GIcon :size="iconSizeBySize" :name="icon" />
    </template>
    {{ title }}
  </div>
</template>
