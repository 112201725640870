<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 11.5C4.32843 11.5 5 10.8284 5 10C5 9.17157 4.32843 8.5 3.5 8.5C2.67157 8.5 2 9.17157 2 10C2 10.8284 2.67157 11.5 3.5 11.5ZM7.83325 11.5C8.66168 11.5 9.33325 10.8284 9.33325 10C9.33325 9.17157 8.66168 8.5 7.83325 8.5C7.00482 8.5 6.33325 9.17157 6.33325 10C6.33325 10.8284 7.00482 11.5 7.83325 11.5ZM13.6667 10C13.6667 10.8284 12.9952 11.5 12.1667 11.5C11.3383 11.5 10.6667 10.8284 10.6667 10C10.6667 9.17157 11.3383 8.5 12.1667 8.5C12.9952 8.5 13.6667 9.17157 13.6667 10ZM16.5 11.5C17.3284 11.5 18 10.8284 18 10C18 9.17157 17.3284 8.5 16.5 8.5C15.6716 8.5 15 9.17157 15 10C15 10.8284 15.6716 11.5 16.5 11.5Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Dots'
};
</script>
