<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.00544 1.25C4.35062 1.25 4.63044 1.52982 4.63044 1.875L4.63044 8.2957C5.70538 8.5754 6.49874 9.55282 6.49874 10.7153C6.49874 11.8778 5.70538 12.8553 4.63044 13.135L4.63044 18.125C4.63044 18.4702 4.35062 18.75 4.00544 18.75C3.66026 18.75 3.38044 18.4702 3.38044 18.125L3.38044 13.1381C2.29934 12.8626 1.5 11.8822 1.5 10.7153C1.5 9.54845 2.29934 8.56806 3.38044 8.29257L3.38044 1.875C3.38044 1.52982 3.66026 1.25 4.00544 1.25ZM2.75 10.7153C2.75 10.0248 3.30956 9.46533 3.99937 9.46533C4.68918 9.46533 5.24874 10.0248 5.24874 10.7153C5.24874 11.4059 4.68918 11.9653 3.99937 11.9653C3.30956 11.9653 2.75 11.4059 2.75 10.7153Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6279 1.25C10.9731 1.25 11.2529 1.52982 11.2529 1.875V5.26184C12.3291 5.54068 13.1237 6.51871 13.1237 7.68213C13.1237 8.84555 12.3291 9.82358 11.2529 10.1024V18.125C11.2529 18.4702 10.9731 18.75 10.6279 18.75C10.2828 18.75 10.0029 18.4702 10.0029 18.125V10.1042C8.9231 9.82789 8.125 8.84811 8.125 7.68213C8.125 6.51615 8.9231 5.53637 10.0029 5.26001V1.875C10.0029 1.52982 10.2828 1.25 10.6279 1.25ZM10.6244 6.43213C9.93456 6.43213 9.375 6.99158 9.375 7.68213C9.375 8.37268 9.93456 8.93213 10.6244 8.93213C11.3142 8.93213 11.8737 8.37268 11.8737 7.68213C11.8737 6.99158 11.3142 6.43213 10.6244 6.43213Z" fill="currentColor"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.1265 1.875C17.1265 1.52982 16.8466 1.25 16.5015 1.25C16.1563 1.25 15.8765 1.52982 15.8765 1.875V10.9334C14.7981 11.2108 14.0015 12.1898 14.0015 13.3547C14.0015 14.5197 14.7981 15.4987 15.8765 15.7761V18.125C15.8765 18.4702 16.1563 18.75 16.5015 18.75C16.8466 18.75 17.1265 18.4702 17.1265 18.125V15.7758C18.2042 15.498 19.0002 14.5192 19.0002 13.3547C19.0002 12.1903 18.2042 11.2115 17.1265 10.9337V1.875ZM15.2515 13.3547C15.2515 12.6642 15.811 12.1047 16.5008 12.1047C17.1906 12.1047 17.7502 12.6642 17.7502 13.3547C17.7502 14.0453 17.1906 14.6047 16.5008 14.6047C15.811 14.6047 15.2515 14.0453 15.2515 13.3547Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Customize'
};
</script>
