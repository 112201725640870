import type { GInputSize, GInputState, GInputStyle } from '../types';

export const INPUT_STYLE_BY_TYPE: Record<GInputStyle, Record<GInputState, { dark: string; light: string }>> = {
  normal: {
    default: {
      dark: 'bg-dark-400 placeholder-text-dark-100 text-text-dark-500',
      light: 'bg-light-300 placeholder-text-light-100 text-text-light-500',
    },
    focus: {
      dark: 'focus:border focus:border-primary-300',
      light: 'focus:border focus:border-primary-300',
    },
    active: {
      dark: 'border border-primary-300',
      light: 'border border-primary-300',
    },
    hover: {
      dark: 'hover:bg-dark-250',
      light: 'hover:bg-light-450',
    },
    disabled: {
      dark: 'bg-dark-400 placeholder-text-dark-100 text-text-dark-100',
      light: 'bg-light-300 placeholder-text-light-100 text-text-light-100',
    },
    error: {
      dark: 'bg-dark-400 placeholder-text-dark-100 text-text-dark-500 border border-red-300',
      light: 'bg-light-300 placeholder-text-light-100 text-text-light-500 border border-red-300',
    },
  },
  inline: {
    default: {
      dark: 'bg-transparent placeholder-text-dark-100 text-text-dark-500',
      light: 'bg-transparent placeholder-text-light-100 text-text-light-500',
    },
    focus: {
      dark: 'focus:border focus:border-primary-300',
      light: 'focus:border focus:border-primary-300',
    },
    active: {
      dark: 'border border-primary-300',
      light: 'border border-primary-300',
    },
    hover: {
      dark: 'hover:bg-dark-250',
      light: 'hover:bg-light-450',
    },
    disabled: {
      dark: 'bg-transparent placeholder-text-dark-100 text-text-dark-100',
      light: 'bg-transparent placeholder-text-light-100 text-text-light-100',
    },
    error: {
      dark: 'bg-transparent placeholder-text-dark-100 text-text-dark-500 border border-red-300',
      light: 'bg-transparent placeholder-text-light-100 text-text-light-500 border border-red-300',
    },
  },
};

export const BUTTON_STYLE_BY_SIZE: Record<GInputSize, string> = {
  large: 'h-40 text-14 leading-normal px-8',
  medium: 'h-36 text-12 leading-normal px-8',
  'semi-medium': 'h-32 text-12 leading-6 px-8',
  normal: 'h-28 text-12 leading-normal px-8',
  small: 'h-24 text-12 leading-normal px-8',
};
