import type { ButtonSize, ButtonState, ButtonType } from '../types/button';

export const BUTTON_STYLE_BY_TYPE: Record<ButtonType, Record<ButtonState, { dark: string; light: string }>> = {
  link: {
    default: {
      dark: 'bg-transparent border-transparent !p-0 text-primary-200',
      light: 'bg-transparent border-transparent !p-0 text-primary-300',
    },
    active: {
      dark: 'bg-transparent border-transparent !p-0 text-primary-150',
      light: 'bg-transparent border-transparent !p-0 text-primary-350',
    },
    hover: {
      dark: 'hover:text-primary-150',
      light: 'hover:text-primary-350',
    },
    disabled: {
      dark: 'bg-transparent border-transparent !p-0 text-text-dark-100',
      light: 'bg-transparent border-transparent !p-0 text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  primary: {
    default: {
      dark: 'bg-primary-300 border-primary-300 text-text-dark-500',
      light: 'bg-primary-300 border-primary-300 text-text-dark-500',
    },
    active: {
      dark: 'bg-underlay-blue-150 border-transparent text-primary-250',
      light: 'bg-underlay-light-blue border-transparent text-primary-300',
    },
    hover: {
      dark: 'hover:bg-primary-250 hover:border-primary-250',
      light: 'hover:bg-primary-350 hover:border-primary-350',
    },
    disabled: {
      dark: 'bg-dark-300 border-dark-300 text-text-dark-100',
      light: 'bg-light-300 border-light-300 text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  secondary: {
    default: {
      dark: 'bg-dark-400 border-dark-400 text-text-dark-500',
      light: 'bg-light-300 border-light-400 text-text-light-500',
    },
    active: {
      dark: 'bg-underlay-blue-150 border-transparent text-primary-250',
      light: 'bg-underlay-light-blue border-transparent text-primary-300',
    },
    hover: {
      dark: 'hover:bg-dark-250 hover:border-dark-250',
      light: 'hover:bg-light-450 hover:border-light-450',
    },
    disabled: {
      dark: 'bg-dark-400 border-dark-400 text-text-dark-100',
      light: 'bg-light-300 border-light-300 text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  tertiary: {
    default: {
      dark: 'bg-dark-300 border-dark-300 text-text-dark-500',
      light: 'bg-light-100 border-light-100 text-text-light-500',
    },
    active: {
      dark: 'bg-underlay-blue-150 border-transparent text-primary-250',
      light: 'bg-underlay-light-blue border-transparent text-primary-300',
    },
    hover: {
      dark: 'hover:bg-dark-150 hover:border-dark-150',
      light: 'hover:bg-light-250 hover:border-light-250',
    },
    disabled: {
      dark: 'bg-dark-300 border-dark-300 text-text-dark-100',
      light: 'bg-light-100 border-light-100 text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  ghost: {
    default: {
      dark: 'bg-transparent border-transparent text-text-dark-500',
      light: 'bg-transparent border-transparent text-text-light-500',
    },
    active: {
      dark: 'bg-underlay-blue-150 border-transparent text-primary-250',
      light: 'bg-underlay-light-blue border-transparent text-primary-300',
    },
    hover: {
      dark: 'hover:bg-dark-150 hover:border-dark-150',
      light: 'hover:bg-light-250 hover:border-light-250',
    },
    disabled: {
      dark: 'bg-transparent border-transparent text-text-dark-100',
      light: 'bg-transparent border-transparent text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  secondaryGhost: {
    default: {
      dark: 'bg-transparent border-transparent text-text-dark-300',
      light: 'bg-transparent border-transparent text-text-light-500',
    },
    active: {
      dark: 'bg-underlay-blue-150 border-transparent text-primary-250',
      light: 'bg-underlay-light-blue border-transparent text-primary-300',
    },
    hover: {
      dark: 'hover:bg-dark-150 hover:border-dark-150',
      light: 'hover:bg-light-250 hover:border-light-250',
    },
    disabled: {
      dark: 'bg-transparent border-transparent text-text-dark-100',
      light: 'bg-transparent border-transparent text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  primaryGhost: {
    default: {
      dark: 'bg-transparent border-transparent text-primary-200',
      light: 'bg-transparent border-transparent text-primary-300',
    },
    active: {
      dark: 'bg-underlay-blue-150 border-transparent text-primary-250',
      light: 'bg-underlay-light-blue border-underlay-light-blue text-primary-300',
    },
    hover: {
      dark: 'hover:bg-underlay-underlay-blue-150 hover:border-underlay-underlay-blue-150',
      light: 'hover:bg-underlay-light-blue hover:border-underlay-light-blue',
    },
    disabled: {
      dark: 'bg-transparent border-transparent text-text-dark-100',
      light: 'bg-transparent border-transparent text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  danger: {
    default: {
      dark: 'bg-red-300 border-red-300 text-text-dark-500',
      light: 'bg-red-300 border-red-300 text-text-dark-500',
    },
    active: {
      dark: 'bg-red-250 border-red-250 text-text-dark-500',
      light: 'bg-red-350 border-red-350 text-text-dark-500',
    },
    hover: {
      dark: 'hover:bg-red-250 hover:border-red-250',
      light: 'hover:bg-red-350 hover:border-red-350',
    },
    disabled: {
      dark: 'bg-dark-400 border-dark-400 text-text-dark-100',
      light: 'bg-light-300 border-light-300 text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },

  dangerGhost: {
    default: {
      dark: 'bg-transparent border-transparent text-red-200',
      light: 'bg-transparent border-transparent text-red-300',
    },
    active: {
      dark: 'bg-underlay-red-100 border-underlay-red-100 text-red-200',
      light: 'bg-underlay-red-100  border-underlay-red-100 text-red-300',
    },
    hover: {
      dark: 'hover:bg-underlay-red-100 hover:border-underlay-red-100',
      light: 'hover:bg-underlay-red-100 hover:border-underlay-red-100',
    },
    disabled: {
      dark: 'bg-transparent border-transparent text-text-dark-100',
      light: 'bg-transparent border-transparent text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
  upgrade: {
    default: {
      dark: 'bg-primary-300 border-primary-300 text-text-dark-500',
      light: 'bg-primary-300 border-primary-300 text-text-dark-500',
    },
    active: {
      dark: 'bg-primary-350 border-primary-350 text-text-dark-500',
      light: 'bg-primary-350 border-primary-350 text-text-dark-500',
    },
    hover: {
      dark: 'hover:bg-primary-350 hover:border-primary-350',
      light: 'hover:bg-primary-350 hover:border-primary-350',
    },
    disabled: {
      dark: 'bg-dark-300 border-dark-300 text-text-dark-100',
      light: 'bg-light-300 border-light-300 text-text-light-100',
    },
    subdued: {
      dark: 'border-transparent !p-0 text-text-dark-100',
      light: 'border-transparent !p-0 text-text-light-100',
    },
  },
};

export const BUTTON_STYLE_BY_SIZE: Record<ButtonSize, string> = {
  large: 'h-40 text-14 leading-normal px-16',
  medium: 'h-36 text-12 leading-normal px-16',
  'semi-medium': 'h-32 text-12 leading-6 px-16',
  normal: 'h-28 text-12 leading-normal px-8',
  small: 'h-24 text-12 leading-normal px-8',
  'extra-small': 'h-20 text-8 leading-normal px-4',
};
