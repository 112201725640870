<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.87891 1.22461C1.53373 1.22461 1.25391 1.50443 1.25391 1.84961C1.25391 2.19479 1.53373 2.47461 1.87891 2.47461L5.48609 2.47461C5.41699 2.6701 5.37939 2.88046 5.37939 3.09961V10.5996C5.37939 11.6351 6.21886 12.4746 7.25439 12.4746H12.7544C13.7899 12.4746 14.6294 11.6351 14.6294 10.5996V3.09961C14.6294 2.88046 14.5918 2.6701 14.5227 2.47461L18.1298 2.47461C18.475 2.47461 18.7548 2.19479 18.7548 1.84961C18.7548 1.50443 18.475 1.22461 18.1298 1.22461H12.7544H7.25439H1.87891ZM12.7554 2.47461C12.7551 2.47461 12.7547 2.47461 12.7544 2.47461H7.25439C7.25416 2.47461 7.25392 2.47461 7.25368 2.47461C6.90883 2.47499 6.62939 2.75467 6.62939 3.09961V10.5996C6.62939 10.9448 6.90922 11.2246 7.25439 11.2246H12.7544C13.0996 11.2246 13.3794 10.9448 13.3794 10.5996V3.09961C13.3794 2.75477 13.1001 2.47516 12.7554 2.47461Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'JustifyContentTop',
};
</script>
