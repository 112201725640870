<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.30975 7.52441C6.27422 7.52441 5.43475 8.36388 5.43475 9.39941V16.8994C5.43475 17.1186 5.47235 17.3289 5.54144 17.5244H1.93433C1.58915 17.5244 1.30933 17.8042 1.30933 18.1494C1.30933 18.4946 1.58915 18.7744 1.93433 18.7744H7.30975H12.8098L18.1852 18.7744C18.5304 18.7744 18.8102 18.4946 18.8102 18.1494C18.8102 17.8042 18.5304 17.5244 18.1852 17.5244H14.5781C14.6472 17.3289 14.6848 17.1186 14.6848 16.8994V9.39941C14.6848 8.36388 13.8453 7.52441 12.8098 7.52441H7.30975ZM12.8098 17.5244C13.1549 17.5244 13.4348 17.2446 13.4348 16.8994V9.39941C13.4348 9.05424 13.1549 8.77441 12.8098 8.77441H7.30975C6.96458 8.77441 6.68475 9.05424 6.68475 9.39941V16.8994C6.68475 17.2446 6.96458 17.5244 7.30975 17.5244H12.8098Z"
      fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'JustifyContentBottom',
};
</script>
