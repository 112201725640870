<template>
  <svg viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M20 9C20 5.68629 22.6863 3 26 3H54C57.3137 3 60 5.68629 60 9V29C60 32.3137 57.3137 35 54 35H26C22.6863 35 20 32.3137 20 29V9Z" fill="#5E5E5E"/>
<path d="M32.5 13.766L37.495 12.0288C37.495 12.0288 38.1024 13.4454 40.005 13.4454C41.9076 13.4454 42.5083 12 42.5083 12L47.5 13.766L46.6692 18.1222H44.1642V26H35.825V18.1196H33.3325L32.5 13.766Z" fill="#D6D6D6"/>
<rect x="27" y="39" width="26" height="6" rx="3" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductLayoutVertical'
};
</script>
