<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path class="text-dark-50" fill-rule="evenodd" clip-rule="evenodd" d="M2 3.5C2 2.67157 2.67157 2 3.5 2H16.5C17.3284 2 18 2.67157 18 3.5V16.5C18 17.3284 17.3284 18 16.5 18H3.5C2.67157 18 2 17.3284 2 16.5L16.5 16.5L16.5 3.5H2Z" fill="currentColor"/>
<path d="M4 18C2.89543 18 2 17.1046 2 16L2 4C2 2.89543 2.89543 2 4 2L4 18Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BorderLeft'
};
</script>
