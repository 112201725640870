<template>
  <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
  <rect width="23.9723" height="23.9999" rx="2.68656" fill="#5E5E5E"/>
  <rect y="26.0002" width="23.9723" height="23.9999" rx="2.68656" fill="#5E5E5E"/>
  <rect x="26.0277" y="0.000244141" width="23.9723" height="23.9999" rx="2.68656" fill="#5E5E5E"/>
  <rect x="26.0278" y="26.0002" width="23.9723" height="23.9999" rx="2.68656" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutTwoCol'
};
</script>
