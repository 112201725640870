<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 4C3.23122 4 2 5.23122 2 6.75V7.75C2 8.16421 2.33579 8.5 2.75 8.5C3.16421 8.5 3.5 8.16421 3.5 7.75V6.75C3.5 6.05964 4.05964 5.5 4.75 5.5H7.25C7.66421 5.5 8 5.16421 8 4.75C8 4.33579 7.66421 4 7.25 4H4.75ZM4.75 16C3.23122 16 2 14.7688 2 13.25V12.25C2 11.8358 2.33579 11.5 2.75 11.5C3.16421 11.5 3.5 11.8358 3.5 12.25V13.25C3.5 13.9404 4.05964 14.5 4.75 14.5H7.25C7.66421 14.5 8 14.8358 8 15.25C8 15.6642 7.66421 16 7.25 16H4.75ZM18 6.75C18 5.23122 16.7688 4 15.25 4H12.75C12.3358 4 12 4.33579 12 4.75C12 5.16421 12.3358 5.5 12.75 5.5H15.25C15.9404 5.5 16.5 6.05964 16.5 6.75V7.75C16.5 8.16421 16.8358 8.5 17.25 8.5C17.6642 8.5 18 8.16421 18 7.75V6.75ZM15.25 16C16.7688 16 18 14.7688 18 13.25V12.25C18 11.8358 17.6642 11.5 17.25 11.5C16.8358 11.5 16.5 11.8358 16.5 12.25V13.25C16.5 13.9404 15.9404 14.5 15.25 14.5H12.75C12.3358 14.5 12 14.8358 12 15.25C12 15.6642 12.3358 16 12.75 16H15.25Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerSingle'
};
</script>
