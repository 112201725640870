<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<rect x="2" y="8.5" width="16" height="3" rx="1" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Line'
};
</script>
