<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path d="M1.25 8.96875C1.25 8.69261 1.47386 8.46875 1.75 8.46875H5.5C5.77614 8.46875 6 8.69261 6 8.96875V10.9688C6 11.2449 5.77614 11.4688 5.5 11.4688H1.75C1.47386 11.4688 1.25 11.2449 1.25 10.9688V8.96875Z" fill="currentColor"/>
<path d="M7.62512 9.03125C7.62512 8.75511 7.84898 8.53125 8.12512 8.53125H11.8751C12.1513 8.53125 12.3751 8.75511 12.3751 9.03125V11.0312C12.3751 11.3074 12.1513 11.5312 11.8751 11.5312H8.12512C7.84898 11.5312 7.62512 11.3074 7.62512 11.0312V9.03125Z" fill="currentColor"/>
<path d="M14 8.96875C14 8.69261 14.2239 8.46875 14.5 8.46875H18.25C18.5261 8.46875 18.75 8.69261 18.75 8.96875V10.9688C18.75 11.2449 18.5261 11.4688 18.25 11.4688H14.5C14.2239 11.4688 14 11.2449 14 10.9688V8.96875Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Dash'
};
</script>
