<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path class="text-dark-50" fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2C17.3284 2 18 2.67157 18 3.5L18 16.5L18 17C18 17.5523 17.5523 18 17 18H16.5H3.5H3C2.44772 18 2 17.5523 2 17V16.5V3C2 2.44772 2.44772 2 3 2H3.5L3.5 16.5H16.5L16.5 2Z" fill="currentColor"/>
<path d="M2 3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V4H2V3Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BorderTop'
};
</script>
