<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="100%" height="100%">
<path d="M10 1.25C10.3452 1.25 10.625 1.52982 10.625 1.875V5C10.625 5.34518 10.3452 5.625 10 5.625C9.65482 5.625 9.375 5.34518 9.375 5V1.875C9.375 1.52982 9.65482 1.25 10 1.25Z" fill="currentColor"/>
<path d="M3.81294 3.8127C4.05702 3.56862 4.45275 3.56862 4.69682 3.8127L6.90653 6.02241C7.15061 6.26648 7.15061 6.66221 6.90653 6.90629C6.66246 7.15037 6.26673 7.15037 6.02265 6.90629L3.81294 4.69658C3.56886 4.4525 3.56886 4.05677 3.81294 3.8127Z" fill="currentColor"/>
<path d="M1.875 9.375C1.52982 9.375 1.25 9.65482 1.25 10C1.25 10.3452 1.52982 10.625 1.875 10.625H5C5.34518 10.625 5.625 10.3452 5.625 10C5.625 9.65482 5.34518 9.375 5 9.375H1.875Z" fill="currentColor"/>
<path d="M15 9.375C14.6548 9.375 14.375 9.65482 14.375 10C14.375 10.3452 14.6548 10.625 15 10.625H18.125C18.4702 10.625 18.75 10.3452 18.75 10C18.75 9.65482 18.4702 9.375 18.125 9.375H15Z" fill="currentColor"/>
<path d="M13.9776 13.0935C13.7335 12.8494 13.3378 12.8494 13.0937 13.0935C12.8496 13.3376 12.8496 13.7333 13.0937 13.9774L15.3034 16.1871C15.5475 16.4311 15.9432 16.4311 16.1873 16.1871C16.4314 15.943 16.4314 15.5473 16.1873 15.3032L13.9776 13.0935Z" fill="currentColor"/>
<path d="M10.625 15C10.625 14.6548 10.3452 14.375 10 14.375C9.65482 14.375 9.375 14.6548 9.375 15V18.125C9.375 18.4702 9.65482 18.75 10 18.75C10.3452 18.75 10.625 18.4702 10.625 18.125V15Z" fill="currentColor"/>
<path d="M16.1871 3.8127C16.4311 4.05677 16.4311 4.4525 16.1871 4.69658L13.9773 6.90629C13.7333 7.15037 13.3375 7.15037 13.0935 6.90629C12.8494 6.66221 12.8494 6.26648 13.0935 6.02241L15.3032 3.8127C15.5473 3.56862 15.943 3.56862 16.1871 3.8127Z" fill="currentColor"/>
<path d="M6.90628 13.9774C7.15036 13.7333 7.15036 13.3376 6.90628 13.0935C6.6622 12.8494 6.26648 12.8494 6.0224 13.0935L3.81269 15.3032C3.56861 15.5473 3.56861 15.943 3.81269 16.1871C4.05677 16.4311 4.4525 16.4311 4.69657 16.1871L6.90628 13.9774Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'Loading'
};
</script>
