<template>
  <svg width="74" height="52" viewBox="0 0 74 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H66C70.4183 0 74 3.58172 74 8V44C74 48.4183 70.4183 52 66 52H8C3.58172 52 0 48.4183 0 44V8Z"
      fill="#212121" />
    <g clip-path="url(#clip0_1029_42464)">
      <path
        d="M6 12C6 8.68629 8.68629 6 12 6H62C65.3137 6 68 8.68629 68 12V40C68 43.3137 65.3137 46 62 46H12C8.68629 46 6 43.3137 6 40V12Z"
        fill="#424242" />
      <path
        d="M36.8467 19.4263C34.4526 16.1912 29.5733 16.1912 27.1792 19.4263L7.16919 46.4655C4.26917 50.3843 7.09597 55.9038 12.003 55.9038H26.9071C24.4077 59.7935 27.2113 65 31.9771 65H71.997C76.904 65 79.7308 59.4805 76.8308 55.5617L56.8208 28.5225C54.4267 25.2874 49.5474 25.2874 47.1533 28.5225L45.3658 30.938L36.8467 19.4263Z"
        fill="#4C4C4C" />
      <rect x="38" y="21" width="24" height="4" rx="1.8125" fill="#AAAAAA" />
      <rect x="50" y="27" width="12" height="4" rx="1.8125" fill="#AAAAAA" />
    </g>
    <path
      opacity="0.3"
      d="M0 8C0 3.58172 3.58172 0 8 0H66C70.4183 0 74 3.58172 74 8V44C74 48.4183 70.4183 52 66 52H8C3.58172 52 0 48.4183 0 44V8Z"
      fill="#0D0D0D" />
    <defs>
      <clipPath id="clip0_1029_42464">
        <path
          d="M6 12C6 8.68629 8.68629 6 12 6H62C65.3137 6 68 8.68629 68 12V40C68 43.3137 65.3137 46 62 46H12C8.68629 46 6 43.3137 6 40V12Z"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HeroBannerRight',
};
</script>
