<template>
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10.0232 4.97143C10.3388 4.97143 10.5946 5.22727 10.5946 5.54286V11.2571C10.5946 11.5727 10.3388 11.8286 10.0232 11.8286C9.70762 11.8286 9.45179 11.5727 9.45179 11.2571V5.54286C9.45179 5.22727 9.70762 4.97143 10.0232 4.97143ZM10 12.7429C9.49505 12.7429 9.08571 13.1522 9.08571 13.6571C9.08571 14.1621 9.49505 14.5714 10 14.5714C10.5049 14.5714 10.9143 14.1621 10.9143 13.6571C10.9143 13.1522 10.5049 12.7429 10 12.7429Z"
      fill="#F88E86" />
  </svg>
</template>

<script>
export default {
  name: 'StatusErrorFilled',
};
</script>
