<template>
  <svg viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path d="M0 41C0 39.3431 1.34315 38 3 38H11.6667C13.3235 38 14.6667 39.3431 14.6667 41V47C14.6667 48.6569 13.3235 50 11.6667 50H3C1.34315 50 0 48.6569 0 47V41Z" fill="#5E5E5E"/>
<path d="M16.6666 41C16.6666 39.3431 18.0098 38 19.6666 38H28.3333C29.9901 38 31.3333 39.3431 31.3333 41V47C31.3333 48.6569 29.9901 50 28.3333 50H19.6666C18.0098 50 16.6666 48.6569 16.6666 47V41Z" fill="#5E5E5E"/>
<path d="M33.3334 41C33.3334 39.3431 34.6765 38 36.3334 38H45C46.6569 38 48 39.3431 48 41V47C48 48.6569 46.6569 50 45 50H36.3334C34.6765 50 33.3334 48.6569 33.3334 47V41Z" fill="#5E5E5E"/>
<path d="M0 3C0 1.34315 1.34315 0 3 0H45C46.6569 0 48 1.34315 48 3V33C48 34.6569 46.6569 36 45 36H3C1.34315 36 0 34.6569 0 33V3Z" fill="#5E5E5E"/>
</svg>
</template>

<script>
export default {
  name: 'ProductImageLayoutBottom'
};
</script>
