<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C5.44772 2 5 2.44772 5 3V3.5H15V3C15 2.44772 14.5523 2 14 2H6ZM15 16.5H5V17C5 17.5523 5.44772 18 6 18H14C14.5523 18 15 17.5523 15 17V16.5ZM16.5 5H17C17.5523 5 18 5.44772 18 6V14C18 14.5523 17.5523 15 17 15H16.5V5ZM3 5C2.44772 5 2 5.44772 2 6V14C2 14.5523 2.44772 15 3 15H3.5V5H3Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'BorderSeparated'
};
</script>
